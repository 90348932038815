body {
  margin: 0;
  font-family: 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(238, 245, 249, 1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.apexcharts-custom-tooltip {
  max-height: 150px;
  overflow-y: auto;
  padding: 8px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 12px;
}

.section-card {
  border-radius: 10px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.03);
}

.shadow {
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.h-254 {
  height: 254px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.leaflet-container {
  background-color: #aad3df;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}